import {MDBTable, MDBTableBody, MDBTableHead} from 'mdb-react-ui-kit'
import { useEffect, useState } from 'react';

function Alerttable(props){
    const [tabledata,setTabledata]=useState("")
    useEffect(()=>{
        const array=[];
        Object.keys(props?.val).forEach(data=>{
            if(props?.selectedVal===data){
                Object.keys(props.val?.[data]).forEach((ele,i)=>{
                    array.push(props.val?.[data][ele]);
                    // console.log(props.val?.[data][ele]);
                })
                setTabledata(array)
            }
        })
      
    },[props?.trigger])

    return(
        <MDBTable striped>
                            <MDBTableHead id="theme" style={{backgroundColor:'#e3f2fd'}}>
                                <tr className="text-center">
                                <th scope='col'><h6 className="fw-bold">Id</h6></th>
                                <th scope='col'><h6 className="fw-bold">Alarm</h6></th>
                                <th scope='col'><h6 className="fw-bold">Last Updated</h6></th>
                                {/* <th scope='col'>Handle</th> */}
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody className="text-center">
                                {
                                    console.log(tabledata)
                                }
                                <tr>
                                <th scope='row'>1</th>
                                <td>Alarm 1</td>
                                <td>2022-10-14 12:00:00</td>
                                </tr>
                                <tr>
                                <th scope='row'>2</th>
                                <td>Alarm 2</td>
                                <td>2022-10-14 12:00:56</td>
                                </tr>
                                <tr>
                                <th scope='row'>3</th>
                                <td>Alarm 3</td>
                                <td>2022-10-14 12:20:00</td>
                                </tr>
                                <tr>
                                <th scope='row'>4</th>
                                <td>Alarm 4</td>
                                <td>2022-10-14 11:59:50</td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
    )
}
export default Alerttable;