import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol  } from 'mdb-react-ui-kit';
import Login from './pages/login';
import Dashboard from './pages/dashboard';
// import Report from './pages/report';
// import Test from './pages/test';
// import { useState } from 'react';

// import {UserContext} from './components/context';



function App() {

  // const [clickedVal,setClickedVal]=useState('BRCD_NOK');
  // const [run,setRun]=useState(false);
  // const[testdata,setTestdata]=useState('');

  return (
    <MDBContainer fluid id='container' className='vh-100'>
      <MDBRow id="row" className="">
        <BrowserRouter>
        {/* <UserContext.Provider value={{clickedVal,setClickedVal,run,setRun,testdata,setTestdata}}> */}
            <Routes>
                <Route
                    path="/"
                    element={ <Login /> }
                />
                <Route
                    path="/dashboard"
                    element={ <Dashboard /> }
                />
                 {/* <Route
                    path="/report"
                    element={ <Report /> }
                />
                 <Route
                    path="/test"
                    element={ <Test /> }
                /> */}
            </Routes>
          {/* </UserContext.Provider> */}
        </BrowserRouter>
        {/* <h6 className='fixed-bottom text-center fw-bold p-2'>© 2022 | Copyright | Hawfinch Technologies</h6> */}
      </MDBRow>
    </MDBContainer>
      
  );
}
export default App;
