import '../css/style.css'
import React, { useState } from 'react';
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBCollapse,
  MDBTypography
} from 'mdb-react-ui-kit';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars,faSquareArrowUpRight} from '@fortawesome/free-solid-svg-icons';
import Logo from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';


export default function App() {
  const changepage = useNavigate();

  const [showNavColor, setShowNavColor] = useState(false);
  const [showNavColorSecond, setShowNavColorSecond] = useState(false);
  const [showNavColorThird, setShowNavColorThird] = useState(false);

  const logout = () =>{
    changepage('/')
  }
  return (
    <>
      <MDBNavbar expand='lg' light id="theme" className='shadow' style={{ backgroundColor: '#e3f2fd' }}>
        <MDBContainer fluid>
          <MDBNavbarBrand href='#'>
            <img src={Logo} alt="logo" height="40"/>
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type='button'
            data-target='#navbarColor02'
            aria-controls='navbarColor02'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setShowNavColorThird(!showNavColorThird)}
          >
            <FontAwesomeIcon icon={faBars} />
          </MDBNavbarToggler>
          <MDBCollapse show={showNavColorThird} navbar>
              <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 bg-se1condary text-center'>
                <MDBTypography tag={'h4'} className="w-100 fw-bold text-mu1ted text-uppe1rcase pt-2">OEE Dashboard</MDBTypography>
              </MDBNavbarNav>
              <div className='d-flex justify-content-center'>
                <MDBTypography tag={'h6'} className="fw-bold text-mu1ted text-uppe1rcase pt-2 d-flex" onClick={logout}>LogOut <FontAwesomeIcon icon={faSquareArrowUpRight} className="mx-2 pt-1"/></MDBTypography>
              </div>
            {/* <MDBNavbarNav className='me-auto mb-2 mb-lg-0'>
              <MDBNavbarItem className='active'>
                <MDBNavbarLink aria-current='page' href='#'>
                  Home
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='#'>Features</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='#'>Pricing</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href='#'>About</MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav> */}
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
}