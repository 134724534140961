import ReactEcharts from 'echarts-for-react';
import {graphic} from 'echarts';

function Downtimechart(){
    const option = {
        // title:{
        // //   show:presentData,
        //   textStyle:{
        //     color:'#FF1744',
        //     fontSize:20
        //   },
        // //   text:"No data found!",
        //   left:'center',
        //   top:'center'
        // },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '2%',
            right: '3%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data:['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
             
            }
          ],
          yAxis: [
            {
              type: 'value',
              min:0,
              max:"dataMax"
            }
          ],
          // dataZoom: [
          //   {
          //     type: 'inside',
          //     start: 0,
          //     end: 100
          //   },
          //   {
          //     start: 0,
          //     end: 30
          //   }
          // ],
          series: [
            {
              name: 'Rejection Code Rate',
              type: 'bar',
              barWidth: '60%',
              data:[120, 200, 150, 80, 70, 110, 130],
              color:new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(213, 0, 249)'
                },
                {
                  offset: 1,
                  color: 'rgb(26, 35, 126)'
                }
              ]),
              animation:false,
              itemStyle: {
                shadowBlur: 10,
                shadowColor: '#9E9E9E',
                shadowOffsetY: 5,
              }
            }
          ]
        };
      
    return <ReactEcharts option={option} />
}
export default Downtimechart;


