import { MDBCard, MDBCol, MDBContainer, MDBRow, MDBTypography,MDBTable, MDBTableHead, MDBTableBody, MDBBadge } from "mdb-react-ui-kit";
import Navbar from '../components/navbar';
import Oeechart from "../components/oee_chart";
import Activitychart from '../components/activity_chart';
import Performancechart from "../components/performance_chart";
import Qualitychart from "../components/quality_chart";
import Downtimechart from "../components/downtime_chart";
import Dailystatuschart from "../components/Dailystatus_chart";
import Alerttable from "../components/alerttable";
import { useState } from "react";
import data from '../config/config.json'
import axios from 'axios';
import url from '../config/urlconfig.json'

let timer;
function Dashboard(){
    const[trigger,setTrigger]=useState(false)
    const[machinename,setMachineName]=useState('SQL-BGF');
    const[res,setRes]=useState('')
// console.log(url);
    const apicall = async()=>{
        try {
            const response = await axios.get(url.baseurl + 'getData');
            setRes(response?.data);
            setTrigger((p)=>!p);
          } catch (error) {
            console.error(error);
          }
    }

    clearInterval(timer);
    timer=setInterval(apicall,3000)

    return(
        <MDBContainer fluid>
            <MDBRow>
                <Navbar />
            </MDBRow>
            
            <MDBRow className="p-2 mt-4">
                <MDBCol size={'lg'}>
                    <MDBCard className="shadow border p-2 mb-2">
                        <MDBTypography tag={'h6'} className="fw-bold text-muted mb-n4">OEE</MDBTypography>
                        <Oeechart data={res} trigger={trigger}/>
                    </MDBCard>
                </MDBCol>
                <MDBCol size={'lg'}>
                    <MDBCard className="shadow border p-2 mb-2">
                        <MDBTypography tag={'h6'} className="fw-bold text-muted mb-n4">Availability</MDBTypography>
                        <Activitychart data={res} trigger={trigger}/>
                    </MDBCard>
                </MDBCol>
                <MDBCol size={'lg'}>
                    <MDBCard className="shadow border p-2 mb-2">
                        <MDBTypography tag={'h6'} className="fw-bold text-muted mb-n4">Performance</MDBTypography>
                        <Performancechart data={res} trigger={trigger}/>
                    </MDBCard>
                </MDBCol>
                <MDBCol size={'lg'}>
                    <MDBCard className="shadow border p-2 mb-2">
                        <MDBTypography tag={'h6'} className="fw-bold text-muted mb-n4">Quality</MDBTypography>
                        <Qualitychart data={res} trigger={trigger}/>
                    </MDBCard>
                </MDBCol>
                <MDBCol size={'lg'}>
                    <MDBCard className="shadow border p-2 mb-2">
                        <MDBTypography tag={'h6'} className="fw-bold text-muted mt-2 mb-4">Today Production</MDBTypography>
                        <MDBBadge pill color='success' light className="mx-2 my-3 shadow"><h5 className="fw-bold pt-2">Parts Made: 5342</h5></MDBBadge>
                        <MDBBadge pill color='danger' light className="mx-2 my-3 shadow"><h5 className="fw-bold pt-2">Defects: 53</h5></MDBBadge>
                        <MDBBadge pill color='primary' light className="mx-2 my-3 shadow"><h5 className="fw-bold pt-2">Parts / Hr: 100</h5></MDBBadge>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
            <MDBRow className="p-2">
                {/* <MDBCol size={'lg'}>
                    <MDBCard className="shadow border p-2 mb-2">
                        <MDBTypography tag={'h6'} className="fw-bold text-muted">Parts Made</MDBTypography>
                        <MDBTypography tag={'h1'} className="fw-bold text-center py-2">5067</MDBTypography>
                    </MDBCard>
                </MDBCol>
                <MDBCol size={'lg'}>
                    <MDBCard className="shadow border p-2 mb-2">
                        <MDBTypography tag={'h6'} className="fw-bold text-muted">Defects</MDBTypography>
                        <MDBTypography tag={'h1'} className="fw-bold text-center py-2">87</MDBTypography>
                    </MDBCard>
                </MDBCol>
                <MDBCol size={'lg'}>
                    <MDBCard className="shadow border p-2 mb-2">
                        <MDBTypography tag={'h6'} className="fw-bold text-muted">Parts / HR</MDBTypography>
                        <MDBTypography tag={'h1'} className="fw-bold text-center py-2">100</MDBTypography>
                    </MDBCard>
                </MDBCol> */}
            </MDBRow>
            <MDBRow className="p-2">
                <MDBCol lg="4">
                    <MDBCard className="shadow border p-2 mb-2">
                        <MDBTypography tag={'h6'} className="fw-bold text-muted">Current Status</MDBTypography>
                        <MDBTable striped>
                            <MDBTableHead id="theme" style={{backgroundColor:'#e3f2fd'}}>
                                <tr className="text-center">
                                <th scope='col'><h6 className="fw-bold text-start">Machine Name</h6></th>
                                <th scope='col'><h6 className="fw-bold">Time</h6></th>
                                <th scope='col'><h6 className="fw-bold">Status</h6></th>
                                {/* <th scope='col'>Handle</th> */}
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody className="text-center">
                                <tr>
                                <td className="text-start" onClick={()=>{setMachineName('SQL-BGF');setTrigger((p)=>!p)}}>SQL-BGF</td>
                                <td>02:50:42</td>
                                <td>
                                <MDBBadge pill color='success' light>
                                    Running
                                </MDBBadge>
                                </td>
                                </tr>
                                <tr>
                                <td className="text-start" onClick={()=>{setMachineName('Gas Nitriding Furnance');setTrigger((p)=>!p)}}>Gas Nitriding Furnance</td>
                                <td>01:02:52</td>
                                <td>
                                <MDBBadge pill color='success' light>
                                    Running
                                </MDBBadge>
                                </td>
                                </tr>
                                <tr>
                                <td className="text-start" onClick={()=>{setMachineName('Field Bench Test');setTrigger((p)=>!p)}}>Field Bench Test</td>
                                <td>00:50:59</td>
                                <td>
                                <MDBBadge pill color='danger' light>
                                    Stopped
                                </MDBBadge>
                                </td>
                                </tr>
                                <tr>
                                <td className="text-start" onClick={()=>{setMachineName('Dust Collector');setTrigger((p)=>!p)}}>Dust Collector</td>
                                <td>05:50:01</td>
                                <td>
                                <MDBBadge pill color='success' light>
                                    Running
                                </MDBBadge>
                                </td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCard>
                </MDBCol>
                <MDBCol lg="4">
                    <MDBCard className="shadow border p-2  mb-2">
                        <MDBTypography tag={'h6'} className="fw-bold text-muted">Downtime Pareto</MDBTypography>
                            <Downtimechart />
                    </MDBCard>
                </MDBCol>
                <MDBCol lg="4">
                    <MDBCard className="shadow border p-2  mb-2">
                        <MDBTypography tag={'h6'} className="fw-bold text-muted">Alarm Status ({machinename})</MDBTypography>
                        <Alerttable val={data} selectedVal={machinename} trigger={trigger}/>
                    </MDBCard>
                </MDBCol>
            </MDBRow>

            <MDBRow className="p-2">
                <MDBCol size={'lg'} className="py-2">
                    <MDBCard className="shadow border p-2">
                        <MDBTypography tag={'h6'} className="fw-bold text-muted">Daily Status ({machinename})</MDBTypography>
                        <Dailystatuschart />
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}
export default Dashboard;