import '../css/login.css';
import '../css/theme.css'
import 'antd/dist/antd.css';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import Logo from '../assets/logo.png';
import { MDBContainer, MDBRow, MDBCol,MDBCard, MDBBtn,MDBTypography, MDBInputGroup  } from 'mdb-react-ui-kit';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser,faKey,faEye,faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
// import ConfigUrl from '../config/urlConfiguration.json';




function Login() {

    const changepage = useNavigate();

    const initialValues={username:"", password:""}
    const [formValues, setFormValues]=useState(initialValues)
    const [formError, setFormError]=useState({})
    const [isSubmit, setIsSubmit]=useState(false)

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShownIcon, setPasswordShownIcon] = useState(false);
    useEffect(()=>{
        if(localStorage.getItem("UserName")!==null){
            changepage('/dashboard')
        }
    },[])

    const handleChange= (e) =>{
        const{ name, value}=e.target;
        setFormValues({...formValues, [name]: value});
    }
    const handleSubmit = (e) =>{
        e.preventDefault();
        setFormError( validate(formValues));
        setIsSubmit(true);
    }
    useEffect(()=>{
        if(Object.keys(formError).length ===0 && isSubmit){
        }

    },[formError])
    const validate =(values) =>{
        const errors={};
        // const regex=/^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/;
        const restrict=/^[a-z, A-Z]/;
        if(!values.username || !restrict.test(values.username)){
            errors.username = "Username is required!";
        }
        if(!values.password){
            errors.password = "Password is required!";
        }
        return errors;
    }
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
        setPasswordShownIcon(passwordShownIcon ? false : true);
      };
    
    function login(){
        let user=formValues.username.replace(/[^a-z]/gi,'').toLowerCase();
        if(user==="hawfinch" && formValues.password==="Hawfinch@123"){
                    message.success('Logged In successfully!');
                    setTimeout(() => {
                       changepage('/dashboard');
                    }, 1000);
                }
                else if(user!=="hawfinch" && formValues.password!=="Hawfinch@123"){
                    message.error("Incorrect username or password!")
                }
    }
    
    return (
    <MDBContainer fluid id='login-container'>
        <MDBRow  className='vh-100 px-5 justify-content-center' id='rowLogin'>
            <MDBCard className='justify-content-center align-self-center p-3 shadow-lg' id='card' style={{width:"80%"}} >
            <MDBRow>
                <MDBCol lg="7" className='d-flex flex-col1umn align-items-center justify-content-center' id='left' >
                    <div className='flex-column'>
                    <div className='text-center'>
                        <img src={Logo} className='img-fluid' />
                        <p className='text-monospace text-muted fs-3'>Next Generation Engineering & IT Solution Provider</p>
                    </div>
                    </div>
                </MDBCol>
                <MDBCol lg ="5" className='text-center p-5'>
                    <form onSubmit={handleSubmit}>
                    <MDBTypography tag='h1' className='display-4 fw-bold mb-5 text-muted'>Login</MDBTypography>
                    <MDBInputGroup className='mt-5' noBorder textBefore={<FontAwesomeIcon icon={faUser} />}>
                        <input className='form-control shadow-none border-0 border-bottom rounded-0' maxLength={15} id="username" name="username"type='text' placeholder='Username' value={formValues.username.replace(/[^a-z]/gi,'')} onChange={handleChange} autoComplete='off'/>
                    </MDBInputGroup>
                    <p className='text-danger text-start px-5'>{ formError.username}</p>
                    <MDBInputGroup className='mt-5' noBorder textBefore={<FontAwesomeIcon icon={faKey} />} textAfter={<FontAwesomeIcon id="eye" icon={passwordShownIcon? faEye:faEyeSlash} onClick={togglePasswordVisiblity}/>}>
                        <input className='form-control shadow-none border-0 border-bottom rounded-0' id="password" name="password" type={passwordShown ? "text" : "password"} placeholder='Password' value={formValues.password} onChange={handleChange}/>
                    </MDBInputGroup>
                    <p className='text-danger text-start px-5'>{ formError.password}</p>
                    <MDBBtn className='mt-5 btn-lg fw-bold rounded-5' color='info' type='submit' id='theme' onClick={login}>Login</MDBBtn>
                    </form>
                </MDBCol>
            </MDBRow>
            </MDBCard>
        </MDBRow>
    </MDBContainer>
    );
}
export default Login;