import ReactEcharts from 'echarts-for-react';
function Oeechart(props){
  let value=props?.data?.Availability*props?.data?.Performance*props?.data?.Quality/10000;
    const gaugeData = [
 
        {
          value:value.toFixed(1),
          name: '',
          title: {
            offsetCenter: ['0%', '2%']
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ['0%', '0%']
          },
          itemStyle: {
            shadowBlur: 10,
            shadowColor: '#9E9E9E',
            shadowOffsetY: 5,
          }
        }
      ];
      const option = {
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: '#2196F3',
                color:'#2196F3'
              }
            },
            axisLine: {
              lineStyle: {
                width: 20,
                // color:'#2196F3'
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: gaugeData,
            title: {
              fontSize: 14
            },
            detail: {
              width: 50,
              height: 14,
              fontSize: 14,
              color: 'auto',
              borderColor: 'auto',
              borderRadius: 20,
              borderWidth: 1,
              formatter: '{value}%'
            }
          }
        ]
      };
      
    return <ReactEcharts option={option} />
}
export default Oeechart;