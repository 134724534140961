import ReactEcharts from 'echarts-for-react';

function Dailystatuschart(){

    const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          show:false,
          type: 'value',
        },
        yAxis: {
          show:false,
          type: 'category',
          data: ['Mon']
        },
        series: [
          {
            name: 'Machine Uptime',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [320],
            color:'#4CAF50'
          },
          {
            name: 'Machine Downtime',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [120],
            color:'#F44336'
          },
          {
            name: 'Machine Uptime',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [390],
            color:'#4CAF50'
          },
          {
            name: 'Machine Downtime',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [20],
            color:'#F44336'
          },
        ]
      };
    return <ReactEcharts option={option} style={{height:'180px'}}/>
}

export default Dailystatuschart;
